import XEUtils from 'xe-utils';
import * as ExcelJS from 'exceljs';
const defaultHeaderBackgroundColor = 'f8f8f9';
const defaultCellFontColor = '606266';
const defaultCellBorderStyle = 'thin';
const defaultCellBorderColor = 'e8eaec';
function getCellLabel(column, cellValue) {
    if (cellValue) {
        if (column.type === 'seq') {
            return XEUtils.toValueString(cellValue);
        }
        switch (column.cellType) {
            case 'string':
                return XEUtils.toValueString(cellValue);
            case 'number':
                if (!isNaN(cellValue)) {
                    return Number(cellValue);
                }
                break;
            default:
                if (cellValue.length < 12 && !isNaN(cellValue)) {
                    return Number(cellValue);
                }
                break;
        }
    }
    return cellValue;
}
function getFooterData(opts, footerData) {
    const { footerFilterMethod } = opts;
    return footerFilterMethod ? footerData.filter((items, index) => footerFilterMethod({ items, $rowIndex: index })) : footerData;
}
function getFooterCellValue($table, opts, rows, column) {
    const cellValue = getCellLabel(column, rows[$table.getVMColumnIndex(column)]);
    return cellValue;
}
function getValidColumn(column) {
    const { childNodes } = column;
    const isColGroup = childNodes && childNodes.length;
    if (isColGroup) {
        return getValidColumn(childNodes[0]);
    }
    return column;
}
function setExcelRowHeight(excelRow, height) {
    if (height) {
        excelRow.height = XEUtils.floor(height * 0.75, 12);
    }
}
function setExcelCellStyle(excelCell, align) {
    excelCell.protection = {
        locked: false
    };
    excelCell.alignment = {
        vertical: 'middle',
        horizontal: align || 'left'
    };
}
function getDefaultBorderStyle() {
    return {
        top: {
            style: defaultCellBorderStyle,
            color: {
                argb: defaultCellBorderColor
            }
        },
        left: {
            style: defaultCellBorderStyle,
            color: {
                argb: defaultCellBorderColor
            }
        },
        bottom: {
            style: defaultCellBorderStyle,
            color: {
                argb: defaultCellBorderColor
            }
        },
        right: {
            style: defaultCellBorderStyle,
            color: {
                argb: defaultCellBorderColor
            }
        }
    };
}
function exportXLSX(params) {
    const msgKey = 'xlsx';
    const { $table, options, columns, colgroups, datas } = params;
    const { $vxe, rowHeight, headerAlign: allHeaderAlign, align: allAlign, footerAlign: allFooterAlign, columnOpts } = $table;
    const { modal, t } = $vxe;
    const { message, sheetName, isHeader, isFooter, isMerge, isColgroup, original, useStyle, sheetMethod } = options;
    const _isCustomColumn = options._isCustomColumn;
    const showMsg = message !== false;
    const mergeCells = $table.getMergeCells();
    const colList = [];
    const footList = [];
    const sheetCols = [];
    const sheetMerges = [];
    let beforeRowCount = 0;
    columns.forEach((column) => {
        const { id, renderWidth } = column;
        sheetCols.push({
            key: id,
            width: XEUtils.ceil(renderWidth / 8, 1)
        });
    });
    // 处理表头
    if (isHeader) {
        // 处理分组
        if (isColgroup && colgroups) {
            colgroups.forEach((cols, rIndex) => {
                const groupHead = {};
                columns.forEach((column) => {
                    groupHead[column.id] = null;
                });
                cols.forEach((column) => {
                    const { _colSpan, _rowSpan } = column;
                    const validColumn = getValidColumn(column);
                    const columnIndex = columns.indexOf(validColumn);
                    const headExportMethod = column.headerExportMethod || columnOpts.headerExportMethod;
                    groupHead[validColumn.id] = headExportMethod ? headExportMethod({ column, options, $table }) : (original ? validColumn.field : column.getTitle());
                    if (_colSpan > 1 || _rowSpan > 1) {
                        sheetMerges.push({
                            s: { r: rIndex, c: columnIndex },
                            e: { r: rIndex + _rowSpan - 1, c: columnIndex + _colSpan - 1 }
                        });
                    }
                });
                colList.push(groupHead);
            });
        }
        else {
            const colHead = {};
            columns.forEach((column) => {
                const { id, field } = column;
                const headExportMethod = column.headerExportMethod || columnOpts.headerExportMethod;
                colHead[id] = headExportMethod ? headExportMethod({ column, options, $table }) : (original ? field : column.getTitle());
            });
            colList.push(colHead);
        }
        beforeRowCount += colList.length;
    }
    // 处理合并
    if (isMerge && !_isCustomColumn) {
        mergeCells.forEach(mergeItem => {
            const { row: mergeRowIndex, rowspan: mergeRowspan, col: mergeColIndex, colspan: mergeColspan } = mergeItem;
            sheetMerges.push({
                s: { r: mergeRowIndex + beforeRowCount, c: mergeColIndex },
                e: { r: mergeRowIndex + beforeRowCount + mergeRowspan - 1, c: mergeColIndex + mergeColspan - 1 }
            });
        });
    }
    const rowList = datas.map(item => {
        const rest = {};
        columns.forEach((column) => {
            rest[column.id] = getCellLabel(column, item[column.id]);
        });
        return rest;
    });
    beforeRowCount += rowList.length;
    // 处理表尾
    if (isFooter) {
        const { footerData } = $table.getTableData();
        const footers = getFooterData(options, footerData);
        const mergeFooterItems = $table.getMergeFooterItems();
        // 处理合并
        if (isMerge && !_isCustomColumn) {
            mergeFooterItems.forEach(mergeItem => {
                const { row: mergeRowIndex, rowspan: mergeRowspan, col: mergeColIndex, colspan: mergeColspan } = mergeItem;
                sheetMerges.push({
                    s: { r: mergeRowIndex + beforeRowCount, c: mergeColIndex },
                    e: { r: mergeRowIndex + beforeRowCount + mergeRowspan - 1, c: mergeColIndex + mergeColspan - 1 }
                });
            });
        }
        footers.forEach((rows) => {
            const item = {};
            columns.forEach((column) => {
                item[column.id] = getFooterCellValue($table, options, rows, column);
            });
            footList.push(item);
        });
    }
    const exportMethod = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(sheetName);
        workbook.creator = 'vxe-table';
        sheet.columns = sheetCols;
        if (isHeader) {
            sheet.addRows(colList).forEach(excelRow => {
                if (useStyle) {
                    setExcelRowHeight(excelRow, rowHeight);
                }
                excelRow.eachCell(excelCell => {
                    const excelCol = sheet.getColumn(excelCell.col);
                    const column = $table.getColumnById(excelCol.key);
                    const { headerAlign, align } = column;
                    setExcelCellStyle(excelCell, headerAlign || align || allHeaderAlign || allAlign);
                    if (useStyle) {
                        Object.assign(excelCell, {
                            font: {
                                bold: true,
                                color: {
                                    argb: defaultCellFontColor
                                }
                            },
                            fill: {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: {
                                    argb: defaultHeaderBackgroundColor
                                }
                            },
                            border: getDefaultBorderStyle()
                        });
                    }
                });
            });
        }
        sheet.addRows(rowList).forEach(excelRow => {
            if (useStyle) {
                setExcelRowHeight(excelRow, rowHeight);
            }
            excelRow.eachCell(excelCell => {
                const excelCol = sheet.getColumn(excelCell.col);
                const column = $table.getColumnById(excelCol.key);
                const { align } = column;
                setExcelCellStyle(excelCell, align || allAlign);
                if (useStyle) {
                    Object.assign(excelCell, {
                        font: {
                            color: {
                                argb: defaultCellFontColor
                            }
                        },
                        border: getDefaultBorderStyle()
                    });
                }
            });
        });
        if (isFooter) {
            sheet.addRows(footList).forEach(excelRow => {
                if (useStyle) {
                    setExcelRowHeight(excelRow, rowHeight);
                }
                excelRow.eachCell(excelCell => {
                    const excelCol = sheet.getColumn(excelCell.col);
                    const column = $table.getColumnById(excelCol.key);
                    const { footerAlign, align } = column;
                    setExcelCellStyle(excelCell, footerAlign || align || allFooterAlign || allAlign);
                    if (useStyle) {
                        Object.assign(excelCell, {
                            font: {
                                color: {
                                    argb: defaultCellFontColor
                                }
                            },
                            border: getDefaultBorderStyle()
                        });
                    }
                });
            });
        }
        // 自定义处理
        if (sheetMethod) {
            const sParams = { options: options, workbook, worksheet: sheet, columns, colgroups, datas, $table };
            sheetMethod(sParams);
        }
        sheetMerges.forEach(({ s, e }) => {
            sheet.mergeCells(s.r + 1, s.c + 1, e.r + 1, e.c + 1);
        });
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            // 导出 xlsx
            downloadFile(params, blob, options);
            if (showMsg && modal) {
                modal.close(msgKey);
                modal.message({ content: t('vxe.table.expSuccess'), status: 'success' });
            }
        });
    };
    if (showMsg && modal) {
        modal.message({ id: msgKey, content: t('vxe.table.expLoading'), status: 'loading', duration: -1 });
        setTimeout(exportMethod, 1500);
    }
    else {
        exportMethod();
    }
}
function downloadFile(params, blob, options) {
    const { $table } = params;
    const { $vxe } = $table;
    const { modal, t } = $vxe;
    const { message, filename, type } = options;
    const showMsg = message !== false;
    if (window.Blob) {
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, `${filename}.${type}`);
        }
        else {
            const linkElem = document.createElement('a');
            linkElem.target = '_blank';
            linkElem.download = `${filename}.${type}`;
            linkElem.href = URL.createObjectURL(blob);
            document.body.appendChild(linkElem);
            linkElem.click();
            document.body.removeChild(linkElem);
        }
    }
    else {
        if (showMsg && modal) {
            modal.alert({ content: t('vxe.error.notExp'), status: 'error' });
        }
    }
}
function checkImportData(tableFields, fields) {
    return fields.some(field => tableFields.indexOf(field) > -1);
}
function importError(params) {
    const { $table, options } = params;
    const { $vxe, _importReject } = $table;
    const showMsg = options.message !== false;
    const { modal, t } = $vxe;
    if (showMsg && modal) {
        modal.message({ content: t('vxe.error.impFields'), status: 'error' });
    }
    if (_importReject) {
        _importReject({ status: false });
    }
}
function importXLSX(params) {
    const { $table, columns, options, file } = params;
    const { $vxe, _importResolve } = $table;
    const { modal, t } = $vxe;
    const showMsg = options.message !== false;
    const fileReader = new FileReader();
    fileReader.onerror = () => {
        importError(params);
    };
    fileReader.onload = (evnt) => {
        const tableFields = [];
        columns.forEach((column) => {
            const field = column.field;
            if (field) {
                tableFields.push(field);
            }
        });
        const workbook = new ExcelJS.Workbook();
        const readerTarget = evnt.target;
        if (readerTarget) {
            workbook.xlsx.load(readerTarget.result).then(wb => {
                const firstSheet = wb.worksheets[0];
                if (firstSheet) {
                    const sheetValues = firstSheet.getSheetValues();
                    const fieldIndex = XEUtils.findIndexOf(sheetValues, (list) => list && list.length > 0);
                    const fields = sheetValues[fieldIndex];
                    const status = checkImportData(tableFields, fields);
                    if (status) {
                        const records = sheetValues.slice(fieldIndex).map(list => {
                            const item = {};
                            list.forEach((cellValue, cIndex) => {
                                item[fields[cIndex]] = cellValue;
                            });
                            const record = {};
                            tableFields.forEach(field => {
                                record[field] = XEUtils.isUndefined(item[field]) ? null : item[field];
                            });
                            return record;
                        });
                        $table.createData(records)
                            .then((data) => {
                            let loadRest;
                            if (options.mode === 'insert') {
                                loadRest = $table.insertAt(data, -1);
                            }
                            else {
                                loadRest = $table.reloadData(data);
                            }
                            return loadRest.then(() => {
                                if (_importResolve) {
                                    _importResolve({ status: true });
                                }
                            });
                        });
                        if (showMsg && modal) {
                            modal.message({ content: t('vxe.table.impSuccess', [records.length]), status: 'success' });
                        }
                    }
                    else {
                        importError(params);
                    }
                }
                else {
                    importError(params);
                }
            });
        }
        else {
            importError(params);
        }
    };
    fileReader.readAsArrayBuffer(file);
}
function handleImportEvent(params) {
    if (params.options.type === 'xlsx') {
        importXLSX(params);
        return false;
    }
}
function handleExportEvent(params) {
    if (params.options.type === 'xlsx') {
        exportXLSX(params);
        return false;
    }
}
/**
 * 基于 vxe-table 表格的增强插件，支持导出 xlsx 格式
 */
export const VXETablePluginExportXLSX = {
    install(vxetable) {
        const { interceptor } = vxetable;
        vxetable.setup({
            export: {
                types: {
                    xlsx: 0
                }
            }
        });
        interceptor.mixin({
            'event.import': handleImportEvent,
            'event.export': handleExportEvent
        });
    }
};
if (typeof window !== 'undefined' && window.VXETable && window.VXETable.use) {
    window.VXETable.use(VXETablePluginExportXLSX);
}
export default VXETablePluginExportXLSX;
